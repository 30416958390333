import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import { ThemeProvider } from "@material-ui/core"
import { theme } from "./themesAndStyles/theme"
import { BrowserRouter } from "react-router-dom"
import { YearScheduleProvider } from "./context/YearScheduleContext"
import { TermsProvider } from "./context/TermsContext"
import ScrollToTop from "./ScrollToTop"
import { LocaleContextProvider } from "./context/LocaleContext"
import App from "./App"
import "./App.css"
import Footer from "./components/Footer"

ReactDOM.render(
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <LocaleContextProvider>
        <TermsProvider>
          <YearScheduleProvider>
            <BrowserRouter>
              <ScrollToTop />
              <App />
              <Footer />
            </BrowserRouter>
          </YearScheduleProvider>
        </TermsProvider>
      </LocaleContextProvider>
    </ThemeProvider>
  </React.Fragment>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
